import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Style Imports
import '../../sass/components/cards.scss';

const Cards = ({
  className,
  variant,
  heading,
  introduction,
  children,
}) => {
    // Class definitions
    const baseClass = 'cards';
    const variantClass = variant ? `cards--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <section className={classes}>
            {heading && (
                <h2 className='cards__heading'>{heading}</h2>
            )}

            {introduction && (
                <p className='cards__introduction'>{introduction}</p>
            )}

            <div className='cards__inner'>
                {children}
            </div>
        </section>
    );
};

Cards.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    heading: PropTypes.string,
    introduction: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Cards.Item = ({
    className,
    variant,
    children,
    destination,
    image,
    imageAlt,
    isNew,
    isFeatured,
}) => {
    // Class definitions
    const baseClass = 'cards__item';
    const variantClass = variant ? `cards__item--${variant}` : '';
    const badgeClass = (isNew || isFeatured) ? 'cards__item--badge' : '';
    const customClass = className;
    const classes = [baseClass, variantClass, badgeClass, customClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            {(isNew || isFeatured) && (
                <span className={`cards__badge${isFeatured ? ' cards__badge--featured' : ''}`}>{isNew ? 'New' : 'Featured'}</span>
            )}

            <Link
                className="cards__link"
                to={destination}
                onClick={() => window.scrollTo(0, 0)}
            >
                    {image && (
                        <div className='cards__image-wrapper'>
                            <img
                                className='cards__image'
                                src={image}
                                alt={imageAlt ? imageAlt : ''}
                                aria-hidden={!imageAlt}
                            />
                        </div>
                    )}

                    <span className='cards__link-content'>
                        {children}
                    </span>
            </Link>
        </div>
    );
};

Cards.Item.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    children: PropTypes.node,
    destination: PropTypes.string.isRequired,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    isNew: PropTypes.bool,
    isFeatured: PropTypes.bool,
    variant: PropTypes.oneOf(['bordered']),
};

export default Cards;