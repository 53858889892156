/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/section.scss';

const Section = ({
    className,
    variants,
    label,
    children,
    heading,
    introduction,
}) => {
    // Classname definitions
    const baseClass = 'section';
    const variantClass = variants ? variants.map(variant => `section--${variant}`).join(' ') : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <section
            className={classes}
            aria-label={label}
        >
            <div className='section__inner'>
                {heading && (
                    <h2 className='section__heading'>{heading}</h2>
                )}

                {introduction && (
                    <p className='section__introduction'>{introduction}</p>
                )}
                
                {children}
            </div>
        </section>
    );
};

Section.propTypes = {
    className: PropTypes.string,
    variants: PropTypes.array,
    label: PropTypes.string,
    children: PropTypes.node.isRequired,
    heading: PropTypes.string,
    introduction: PropTypes.string,
};

export default Section;